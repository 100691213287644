import {mapFilters} from "@/utils/helpers";

const initState = () => ({
  orders: {
    total: 0,
    items: [],
    options: {},
  },
  orderNotes: [],
  orderItemImages: [],
  jobOrderItems: [],

})

export const state = initState

export const getters = {
}

export const mutations = {

  setOrderNotes(state, {notes}) {
    state.orderNotes = notes
  },

  setOrderItemImages(state, {images}) {
    state.orderItemImages = images
  },

  setJobImages(state, {jobImages}) {
    state.jobImages = jobImages
  },
}

export const actions = {

  async fetchOrderNotes({commit, state}, {jobID}) {
    const result = await this.$axios.$get(`/JobOrders/${jobID}/notes`)
    if (result) {
      commit('setOrderNotes', {notes: result})
    }
    return result
  },

  async saveOrderNote({commit, state}, {note, image, jobID}) {
    const formData = new FormData()
    formData.append('image', image)
    formData.append('note', note)
    formData.append('jobOrderID', jobID)
    return await this.$axios.$post(`/JobOrders/notes`, formData)

  },


  async fetchOrderItemImages({commit, state}, {jobID}) {
    const result = await this.$axios.$get(`/webpos/order/${jobID}/item-images/`)
    if (result && !result.hasError) {
      commit('setOrderItemImages', {images: result.value})
      return result.value
    }
    else{
      this.$toast.error(result.errorMessages[0])
    }
  },

  removeImages({commit, state}){
    commit('setOrderItemImages', {images: []})
  },


  //get the order items
  async fetchOrderItems({commit, state}, orderID) {
    const res = await this.$axios.get(`webpos/order/${orderID}/items`)
    if(res && res.data && !res.data.hasErrors){
      return res.data.value
    }
    else{
      this.$toast.error('Error occured while getting order detail');
      return [];
    }
  },

  async fetchCheckoutDetail({commit, state}, orderID) {
   try{
     const res = await this.$axios.get(`webpos/order/${orderID}/checkout-detail`)
     if(res && res.data && !res.data.hasErrors){
       return res.data.value
     }
     else{
       this.$toast.error('Error occured while getting order detail');
       return null
     }
   }
   catch{
     this.$toast.error('Error occured while getting order detail');
     return null
   }
  },

  async getInvoiceDetailForOrder({}, {orderID}) {
    try{
      const result = await this.$axios.$get(`/webpos/order/${orderID}/invoice-detail`);

      if(result && !result.hasErrors){
        return result.value;
      }
      else{
        this.$toast.error('Error while getting invoice details.')
        return {};
      }
    }
    catch{
      this.$toast.error('Error while getting invoice detail.')
      return {};
    }
  },

  async getJobTypeStatusTypes({}, {jobTypeCode}) {
    let statuses = []
    try{
      const url = `/webpos/order/${jobTypeCode}/statuses`;

      const result = await this.$axios.$get(url);

      if(result && !result.hasErrors){
        statuses = result.value;
      }
      else{
        this.$toast.error('Error while getting job type statuses')

      }
    }
    catch{
      this.$toast.error('Error while getting job type statuses')
    }

    return statuses;
  },

  async fetchOrderDetail({commit}, orderID) {
    let orderDetail = [];
    try{
      const result = await this.$axios.$get(`webpos/order/${orderID}/detail`);

      if(result && !result.hasErrors){
        orderDetail =  result.value;
      }
      else{
        this.$toast.error('Error while getting order details.')
      }
    }
    catch{
      this.$toast.error('Error while getting order details.')
    }
    return orderDetail;
  },

  async fetchOrderDetailByEncryptedId({commit}, encryptedID) {
    let orderDetail = [];
    try{
      const result = await this.$axios.$get(`webpos/order/by-encrypted-id/?encryptedID=${encodeURIComponent(encryptedID)}`);

      if(result && !result.hasErrors){
        orderDetail =  result.value;
      }
      else{
        this.$toast.error('Error while getting order details.')
      }
    }
    catch{
      this.$toast.error('Error while getting order details.')
    }
    return orderDetail;
  },

  async fetchOrderImages({commit, state}, {jobID}){
    let images = [];
    try{
      const result = await this.$axios.$get(`/webpos/order/${jobID}/images`)
      if (result && !result.hasError) {
        images = result.value;
      }
      else{
        this.$toast.error(result.errorMessages[0]);
      }
    } catch(error){
      this.$toast.error("Error while getting job images");
    }

    return images;
  },
  async sendOrderEmail({commit, state}, orderID) {
    try{
      const result = await this.$axios.$post(`/webpos/order/${orderID}/send-email`);
      if(result && !result.hasErrors){
        this.$toast.success('Email sent successfully');
      }
      else{
        this.$toast.error('Error while sending email')
      }
    }
    catch{
      this.$toast.error('Error while sending email')
    }
  }
}
